import i18n from '@/libs/i18n'

export default {
  quoteStatusName(status) {
    switch (status) {
      case 'pending':
        return i18n.t('pending')
      case 'awaiting_customer_response':
        return i18n.t('awaiting_customer_response')
      case 'buyout':
        return i18n.t('buyout')
      case 'bidding':
        return i18n.t('bidding')
      case 'awaiting_bid_approval':
        return i18n.t('awaiting_bid_approval')
      case 'bid_approved':
        return i18n.t('bid_approved')
      case 'bid_rejected':
        return i18n.t('rejected')
      default:
        return ''
    }
  },
  quoteStatusColor(status) {
    switch (status) {
      case 'pending':
        return 'secondary'
      case 'awaiting_customer_response':
        return 'info'
      case 'buyout':
        return 'success'
      case 'bidding':
        return 'warning'
      case 'awaiting_bid_approval':
        return 'info'
      case 'bid_approved':
        return 'info'
      case 'bid_rejected':
        return 'danger'
      default:
        return ''
    }
  },
  paymentStatusName(status) {
    switch (status) {
      case 'payment_pending':
        return i18n.t('payment_pending')
      case 'awaiting_payment_approval':
        return i18n.t('awaiting_payment_approval')
      case 'payment_approved':
        return i18n.t('payment_approved')
      case 'payment_rejected':
        return i18n.t('payment_rejected')
      default:
        return ''
    }
  },
  paymentStatusColor(status) {
    switch (status) {
      case 'awaiting_payment_approval':
        return 'secondary'
      case 'payment_approved':
        return 'success'
      case 'payment_rejected':
        return 'danger'
      default:
        return ''
    }
  },

  shippingStatusName(status) {
    switch (status) {
      case 'shipping_pending':
        return i18n.t('shippingPending')
      case 'awaiting_shipping_approval':
        return i18n.t('awaitingShippingApproval')
      case 'shipping_approved':
        return i18n.t('shippingApproved')
      case 'shipping_rejected':
        return i18n.t('shippingRejected')
      default:
        return ''
    }
  },

  shippingStatusColor(status) {
    switch (status) {
      case 'shipping_pending':
        return 'secondary'
      case 'awaiting_shipping_approval':
        return 'info'
      case 'shipping_approved':
        return 'success'
      case 'shipping_rejected':
        return 'danger'
      default:
        return ''
    }
  },

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  },

  transpose(mx) {
    const h = mx[0].length
    const w = mx.length
    // const matrix = [...mx]
    // const matrixT = Array(w).fill([])
    const t = []
    for (let i = 0; i < h; i += 1) {
      // Insert a new row (array)
      t[i] = []

      // Loop through every item per item in outer array (width)
      for (let j = 0; j < w; j += 1) {
        // Save transposed data.
        t[i][j] = mx[j][i]
      }
    }
    return t
  },

  validZone(addressState = '', formattedAddress = '') {
    const validStates = ['nuevo leon', 'quintana roo', 'coahuila']
    const validCitiesQuintanaRoo = [
      'cancun',
      'puerto morelos',
      'playa del carmen',
      'akumal',
      'tulum',
    ]
    const validCitiesCoahuila = [
      'saltillo',
    ]

    const normalizedState = addressState
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()

    const isValidState = validStates.includes(normalizedState)

    let isValidAddress = true
    if (normalizedState === 'quintana roo') {
      const normalizedAddress = formattedAddress
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()

      isValidAddress = validCitiesQuintanaRoo.some(city => normalizedAddress.includes(city))
    } else if (normalizedState === 'coahuila') {
      const normalizedAddress = formattedAddress
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()

      isValidAddress = validCitiesCoahuila.some(city => normalizedAddress.includes(city))
    }

    return isValidAddress && isValidState
  },
}
